<script setup lang="ts">
// This component is a wrapper around Tairo's Modal component, but with defaults that we use in our application,
// to avoid unnecessary boilerplate code.
// However, I'm not 100% if this is the best approach. Maybe using Tairo's modal directly allows for more flexibility.
// I'll try it for now and re-evaluate later.

const { open, title } = defineProps<{
  /**
   * Whether the modal is open.
   */
  open?: boolean
  /**
   * The title to show in the header of the modal. It's also possible to pass in a slot with the name "title".
   */
  title?: string
}>()

const emit = defineEmits(['close'])

defineOptions({
  inheritAttrs: false,
})

const closeModal = () => {
  emit('close')
}
</script>

<template>
  <Teleport to="body">
    <TairoModal
      :open="open"
      v-bind="$attrs"
      @close="closeModal"
    >
      <template #header>
        
        <div class="flex w-full items-center justify-between p-4 md:p-6">
          <div class="flex items-center gap-x-2">
            <slot name="avatar" />
            <h3
              class="font-heading text-muted-900 text-lg font-medium leading-6 dark:text-white"
            >
              <slot name="title">
                {{ title }}
              </slot>
            </h3>
          </div>

          <BaseButtonClose @click="closeModal" />
        </div>
      </template>

      
      
      <div class="max-h-[75vh] overflow-auto p-4 md:p-6 md:pt-2">
        <slot />
      </div>

      
      
      <template #footer>
        <div class="p-4 md:p-6">
          <div class="flex gap-x-2">
            <slot name="buttons" />
          </div>
        </div>
      </template>
    </TairoModal>
  </Teleport>
</template>
